import Box from "@material-ui/core/Box";
import Table from "../../components/common/Table/table";
import EnhancedTableToolbar from "../../components/common/Table/tableToolbar";
import ToolbarSelected from "../../components/common/Table/tableToolbarSelected";
import ToolbarDefault from "../../components/common/Table/tableToolbarDefault";
import CalendarFilters from "../../components/common/Filters/calendarFilters";
import AccountPayablesFilters from "../reportAccountPayableSubWorkList/accountPayablesSubWorkFilters";
import FiltersAppliedList from "../../components/common/Filters/filtersAppliedList";
import React, { useCallback, useEffect, useState } from "react";
import { useTableDispatch, useTableState } from "../../contexts/tableContext";
import {
  getFilterData,
  useFilterDispatch,
  useFilterState,
} from "../../contexts/filterContext";
import {
  getBillingList,
  getBillingListDataSet,
  searchBilling,
  useBillingDispatch,
  useBillingState,
} from "../../contexts/billingApSubContext";
import WorkticketSoloStatusChip from "../../components/ui/Worktickets/WorkticketSoloStatusChip";
import useStyles from "./styles";
import { dateFormatField } from "../../components/util/timeFormat";
import { StyledTab } from "../../components/common/Table/stylesTabs";
import TableTabs from "../../components/common/Table/tableTabs";
import { withRouter } from "react-router-dom";
import LoadingIndicator from "../../components/common/LoadingIndicator/loadingIndicator";
import Counter from "../../components/common/TableList/counter";
import LinearProgress from "@material-ui/core/LinearProgress";
import WorkticketFilters from "./WorkticketFilters";
import { partnersTabOptions } from "../../constants";
import { StyledTab2 } from "./stylesTabs";
import WorkTableTabs from "./tableTabs";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useAddDiscountModal } from "./context/AddDiscountDialogueContext";
import { WorkticketMenuActions } from "./WorkticketMenuActions";
import { AddWorkticketCommentDialogue } from "./AddCommentDialogue";
import { AddDiscountDialogue } from "./AddDiscountDialogue";
import { WorkticketModal } from "./WorkticketModal";
import { useAddCommentModal } from "./context/AddCommentDialogueContext";
import { useWorkticketModal } from "./context/WorkticketModalContext";
import { VariousTextComponent } from "./VariousTextComponent";

const AccountPayableWorkticketTable = (props) => {
  const columns = [
    // {
    //   id: "workticket_number",
    //   label: "Workticket",
    //   format: "id",
    //   path: "/worktickets",
    //   checkbox: {
    //     active: (row) => true,
    //   },
    //   content: (row) => (
    //     <Box className={classes.idTableContainer}>
    //       <Typography variant="body1">{row.workticket_number}</Typography>
    //     </Box>
    //   ),
    // },
    {
      id: "id",
      path: "/worktickets",
      format: "id",
      checkbox: {
        active: (row) => true,
      },
      label: "ID",
      content: (row) => `${row.id} `,
    },
    // {
    //   id: "job",
    //   path: "/worktickets",
    //   checkbox: {
    //     active: (row) => true,
    //   },
    //   label: "Subcontractor",
    //   content: (row) => `${row.job_number} - ${row.job_description}`,
    // },
    {
      id: "job",
      path: "/worktickets",
      checkbox: {
        active: (row) => true,
      },
      label: "Job",
      content: (row) => `${row.job_number} - ${row.job_description}`,
    },
    {
      id: "manager",
      label: "Manager",
    },
    {
      id: "workticket_summary",
      label: "WT Summary",
    },
    {
      id: "compliance",
      label: "Compliance",
    },
    {
      id: "workticket.status",
      label: "WT Status",
      content: (row, index) =>
        row.job_number % 2 === 0 ? (
          <VariousTextComponent />
        ) : (
          <WorkticketSoloStatusChip
            status={row.workticket_status}
            archived={0}
          />
        ),
      disableSort: true,
    },
    { id: "workticket_start_date", label: "Due Date", format: "date" },
    {
      id: "total_amount",
      label: "Payment",
      format: "money",
    },
    {
      id: "discount",
      label: "Discount",
    },
    {
      id: "after_discount",
      label: "After Discount Partner",
    },
    // {
    //   id: "workticket.status",
    //   label: "WT Status",
    //   content: (row) => (
    //     <WorkticketSoloStatusChip status={row.workticket_status} archived={0} />
    //   ),
    //   disableSort: true,
    // },
  ];
  const classes = useStyles();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchBilling = useBillingDispatch();
  const dispatchFilters = useFilterDispatch();
  const [workticketModalAction, dispatchWorkticketModal] = useWorkticketModal();
  const { isWorkticketModalOpen } = workticketModalAction;
  const {
    filters,
    startDateActive,
    endDateActive,
    startDate,
    endDate,
    breakdown,
  } = useFilterState();
  const { billingListLoading, billingList, billingCount, currentTab } =
    useBillingState();
  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };
  const handleModuleChange = (event, newTab) => {
    const { history } = props;
    if (newTab === "0") {
      history.push(`/financial/report/account-payable-sub`);
    }
    if (newTab === "1") {
      history.push(`/financial/report/account-payable-work-sub`);
    }
    if (newTab === "2") {
      history.push(`/financial/report/account-payable-sub-workticket`);
    }
  };
  const { hasRowsClickable, selected } = useTableState();
  const handleScrollClick = useCallback(async () => {
    if (billingCount <= billingList.length || loadingMore) {
      return;
    }
    try {
      setLoadingMore(true);
      const filterData = getFilterData(filterState);

      await getBillingListDataSet(
        billingList,
        billingList.length,
        dispatchBilling,
        filterData,
        filterState.searchString
      );

      const billingDataUpdate = billingList ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    billingList,
    billingCount,
    filterState,
    dispatchBilling,
    dispatchTable,
    loadingMore,
  ]);
  function toggleWorkticketModal(open) {
    dispatchWorkticketModal({ type: "TOGGLE_WORKTICKET_MODAL", open: open });
  }
  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          let results = await searchBilling(query, filterData);

          // Send result to search tab
          dispatchBilling({
            type: "SET_BILLING_LIST",
            billingList: results.list,
          });

          dispatchBilling({
            type: "SET_BILLING_COUNT",
            billingCount: results.count,
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };
  useEffect(() => {
    dispatchTable({ type: "UPDATE_COLUMNS", columns: columns });
    dispatchTable({ type: "UPDATE_ROWS", rows: billingList });
  }, [dispatchTable, billingList]);
  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
  };
  const [discountAction, dispatchDiscountAction] = useAddDiscountModal();
  const { isOpenAddDialog } = discountAction;
  const toggleModal = (open) => {
    dispatchDiscountAction({ type: "TOGGLE_CONTACT_DIALOG", open });
  };
  useEffect(() => {
    const fetchData = async () => {
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });
      const filterData = getFilterData(filterState);
      await getBillingList(dispatchBilling, filterData);
    };

    if (filterState.searchString === "") {
      fetchData();
    }
  }, [dispatchBilling, filterState]);
  const IndicatorUpdateTab = ({ tab }) => {
    // if (tabUpdated && tabUpdated.tab === tab) {
    //   return <DotIcon className={classes[`newIndicator${tab}`]} />;
    // }
    return null;
  };

  const LabelTab = ({ tab, label }) => {
    // if (tabUpdated && tabUpdated.tab === tab) {
    return (
      <>
        {label}
        {/*<span className={classes[`newLabel${tab}`]}>*/}
        {/*  {`${tabUpdated.signal ? tabUpdated.signal : "+"}${*/}
        {/*    tabUpdated.count*/}
        {/*  }`}*/}
        {/*</span>*/}
      </>
    );
    // }
    return label;
  };
  return (
    <>
      <Box className={classes.root}>
        {billingListLoading || loadingSearch ? (
          <LoadingIndicator />
        ) : (
          <>
            <Box className={classes.contentContainer}>
              {/*<WorkticketFilters />*/}
              <TableTabs handleTabChange={handleModuleChange} value="2">
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Checks</Box>}
                  key="0"
                  value="0"
                />
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Worktickets</Box>}
                  key="1"
                  value="1"
                />
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Payments</Box>}
                  key="2"
                  value="2"
                />
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Pending Payment</Box>}
                  // key={FILE_INDEX}
                  // value={FILE_INDEX}
                />
                <StyledTab
                  disableRipple={true}
                  label={
                    <Box className={classes.tabItem}>
                      Payment Processed Partner
                    </Box>
                  }
                  // key={FILE_INDEX}
                  // value={FILE_INDEX}
                />
              </TableTabs>
              <Box className={classes.contentCounter}>
                <Counter>{`${billingList.length} / ${billingCount}`}</Counter>
              </Box>
              <Box></Box>
              <Box className={classes.rootContainer}>
                <Box className={classes.tableContainer}>
                  <Box className={classes.fullHeightTable}>
                    <Table
                    // handleRowClick={handleRowClick}
                    // newColumns={columns}
                    // handleScrollClick={handleScrollClick}
                    // activeMobile={true}
                    >
                      <EnhancedTableToolbar>
                        {selected.length > 0 ? (
                          <ToolbarSelected>
                            <Box className={classes.toolbarActionDiscount}>
                              <Button className={classes.deleteDiscountButton}>
                                Delete Discount
                              </Button>
                              <Button
                                onClick={() => {
                                  toggleModal(true);
                                }}
                                className={classes.addDiscountButton}
                              >
                                Add Discount
                              </Button>
                            </Box>
                          </ToolbarSelected>
                        ) : (
                          <ToolbarDefault
                            handleSearch={handleSearch}
                            exportAllowed={true}
                            handleSearchClear={handleSearchClear}
                          >
                            <Box className={classes.filtersDateMainContainer}>
                              <Box
                                className={
                                  classes.dateRangeAndCalendarPickerContainer
                                }
                              >
                                <CalendarFilters
                                  newStyle={true}
                                  chipActive={startDateActive}
                                />
                                {startDateActive && (
                                  <Box className={classes.dateRangeContainer}>
                                    <Box>
                                      <Typography variant="body1">
                                        Date Range:
                                      </Typography>
                                    </Box>
                                    <Box>
                                      {moment(startDate).format("L")} -{" "}
                                      {moment(endDate).format("L")}
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                              <AccountPayablesFilters />
                              <WorkticketFilters />
                            </Box>
                          </ToolbarDefault>
                        )}
                      </EnhancedTableToolbar>
                      {filterState.filters && (
                        <FiltersAppliedList hideDate={true} />
                      )}
                    </Table>
                  </Box>
                </Box>
              </Box>
              {loadingMore ? (
                <Box className={classes.loadingTable}>
                  <LinearProgress color="secondary" />
                </Box>
              ) : null}
            </Box>
          </>
        )}
      </Box>
      {/*{isWorkticketModalOpen && (*/}
      {/*  <WorkticketModal*/}
      {/*    columns={columns}*/}
      {/*    handleScrollClick={handleScrollClick}*/}
      {/*    handleRowClick={handleRowClick}*/}
      {/*  />*/}
      {/*)}*/}
      {isOpenAddDialog && <AddDiscountDialogue />}
      <AddWorkticketCommentDialogue />
    </>
  );
};
export default withRouter(AccountPayableWorkticketTable);
