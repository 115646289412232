import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import EnhancedTableHead from "./tableHead";
import TableMultipleHead from "./tableMultipleHead";
import TableRow from "./tableRow";
import TableMobile from "./tableMobile";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import SearchFeedback from "../LunaFeedback/index";
import { useTableState } from "contexts/tableContext";

const EnhancedTable = (props) => {
  const classes = useStyles();
  const [mobile, setMobile] = useState(false);
  const { currentTab, isMultipleHeader } = useTableState();
  const { idscrollwrapper, lunaSearchData } = props;
  const idWrapper = idscrollwrapper ?? "table-wrapper";

  useEffect(() => {
    if (props.activeMobile) {
      if (window.innerWidth < 960) {
        setMobile(true);
      }

      window.addEventListener("resize", () => {
        if (window.innerWidth < 960) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      });
    }
  }, [props.activeMobile]);

  useEffect(() => {
    const { handleScrollClick } = props;
    const trackScrolling = (e) => {
      const bottom =
        e.target.scrollHeight - (e.target.scrollTop + 10) <=
        e.target.clientHeight;
      if (bottom) {
        if (handleScrollClick) {
          handleScrollClick();
        }
        document
          .getElementById(idWrapper)
          .removeEventListener("scroll", trackScrolling);
      }
    };
    document
      .getElementById(idWrapper)
      .addEventListener("scroll", trackScrolling);
    return () => {
      document
        .getElementById(idWrapper)
        .removeEventListener("scroll", trackScrolling);
    };
  }, [props, idWrapper]);

  useEffect(() => {
    document.getElementById(idWrapper).scrollTop = 0;
  }, [currentTab, idWrapper]);

  return (
    <Box className={classes.root}>
      {props.children}
      <Box
        className={
          props.isDashboard
            ? classNames(classes.tableWrapper, classes.maxHeightTableInSlider)
            : classNames(classes.tableWrapper)
        }
        id={props.idscrollwrapper ? props.idscrollwrapper : "table-wrapper"}
      >
        {props.showNoSearchResultAi ? (
          <SearchFeedback lunaSearchData={lunaSearchData} isNoResults={true} />
        ) : !mobile ? (
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {isMultipleHeader ? (
              <TableMultipleHead />
            ) : (
              <EnhancedTableHead
                hideHeaderCheckbox={props.hideHeaderCheckbox}
              />
            )}
            <TableBody>
              <React.Fragment>
                <TableRow
                  hideHeaderCheckbox={props.hideHeaderCheckbox}
                  checkIcon={props.checkIcon}
                  handleRowClick={props.handleRowClick}
                />
              </React.Fragment>
            </TableBody>
          </Table>
        ) : (
          <TableMobile handleRowClick={props.handleRowClick} />
        )}
        {/* {!mobile ? (
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {isMultipleHeader ? <TableMultipleHead /> : <EnhancedTableHead />}
            <TableBody>
              <React.Fragment>
                <TableRow handleRowClick={props.handleRowClick} />
              </React.Fragment>
            </TableBody>
          </Table>
        ) : (
          <TableMobile handleRowClick={props.handleRowClick} />
        )} */}
      </Box>
    </Box>
  );
};

export default EnhancedTable;
